<template>
  <div id="app">
    <Body />
  </div>
</template>

<script>
import Body from './components/Body.vue'

export default {
  name: 'App',
  components: {
    Body
  },
  metaInfo: {
    title: "Loading",
  },
  mounted() {
    document.title = this.$config.webConfig.header.title;
  }
}
</script>

<style>
  @import "https://fonts.googleapis.com/css?family=Sora:600,600italic,700,700italic,500,500italic,400,400italic,300,300italic%7CInter:400,400italic,300,300italic";
</style>